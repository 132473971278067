import validate from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45global from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/middleware/analytics.global.ts";
import redirects_45global from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@energysage/storyblok-shared/middleware/redirects.global.ts";
import manifest_45route_45rule from "/home/runner/work/es-cms-pages/es-cms-pages/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  analytics_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}