import { datadogRum } from '@datadog/browser-rum';

// https://app.datadoghq.com/rum/application/a3b23589-e9d3-4321-9a52-8c7a30f3fb22
export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();

    if (import.meta.dev) return;

    datadogRum.init({
        applicationId: 'a3b23589-e9d3-4321-9a52-8c7a30f3fb22',
        clientToken: 'pubf858143fd365f7daa753238a262603fe',
        site: 'datadoghq.com',
        service: 'es-cms-pages',
        env: config.public.DEPLOY_ENV,
        version: config.public.VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        trackSessionAcrossSubdomains: true,
    });

    datadogRum.startSessionReplayRecording();
});
